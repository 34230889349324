<template>
    <div style="width: 100%;">
        <div v-if="this.levelOneLabel == null" style="display: flex; flex-direction: column; margin-bottom: 48px; width: 100%;">
          <button class="listOption" @click="selectlevelOne($event)" v-for="item in basel" :key="item.id">
            {{ item.label }}
          </button>
        </div>
        <div class="levelOneSelected body2" v-if="levelOneLabel != null" style="width: 100%; display: flex; justify-content: space-between; align-items: center;">
          {{ this.levelOneLabel }}
          <button  v-if="this.editMode == true" class="editButton" @click="editlevelOne" >
            <i class="pi pi-pencil" style="font-weight: 700;"></i>
            Editar
          </button>
        </div>
        <div v-if="levelOneId != null && levelTwoLabel == null" style="width: 100%; display: flex; flex-direction: column;">
          <div v-for="item in basel" :key="item.id">
            <div style="display: flex; flex-direction: column;" v-if="item.id === levelOneId">
              <button class="listOption" style="padding: 20px 32px 20px 64px;" @click="selectlevelTwo($event, item)" v-for="child in item.children" :key="child.id">
                {{ child.label }}
              </button>
            </div>
          </div>
        </div>
        <div class="levelTwoSelected body2" v-if="levelTwoLabel != null && levelOneId != null" style="width: 100%; display: flex; justify-content: space-between; align-items: center;">
          {{ this.levelTwoLabel }}
          <button  v-if="this.editMode == true" class="editButton" @click="editlevelTwo" >
            <i class="pi pi-pencil" style="font-weight: 700;"></i>
            Editar
          </button>
        </div>
        <div v-show="levelThreeLabel == null && levelTwoLabel != null && levelOneId != null" style="width: 100%; display: flex; flex-direction: column;">
            <button class="listOption" style="padding: 20px 32px 20px 96px;" @click="selectlevelThree($event, item.id)" v-for="item in levelThreeList" :key="item.id">
                {{ item.label }}
            </button>
        </div>
        <div class="levelThreeSelected body2" v-if="levelTwoLabel != null && levelOneId != null && levelThreeLabel != null" style="width: 100%; display: flex; justify-content: space-between; align-items: center;">
          {{ this.levelThreeLabel }}
          <button  v-if="this.editMode == true" class="editButton" @click="editlevelThree" >
            <i class="pi pi-pencil" style="font-weight: 700;"></i>
            Editar
          </button>
        </div>
    </div>
  </template>
  
  <script>
  import "../Global.css"
  import IncidentService from "../service/IncidentService.js";
  
  export default {
    data() {
      return {
        basel: null,
        levelOneLabel: null,
        levelOneId: null,
        levelTwoLabel: null,
        levelTwoId: null,
        levelThreeLabel: null,
        levelThreeId: null,
        levelThreeList: null,
      }
    },
    props: {
      baselLevel: Object,
      editMode: Boolean,
    },
    mounted() {
      this.basel = IncidentService.incidentFields.baselLevel.data;
      this.localBaselLevel = this.baselLevel
      this.checkBaselFilled();
    },
    methods: {
      checkBaselFilled(){
        if(this.baselLevel.levelOne != null){
          this.levelOneId = this.baselLevel.levelOne
          this.basel.forEach((item) => {
            if(this.baselLevel.levelOne === item.id){
                this.levelOneLabel = item.label
                if(this.levelOneId != null){
                  this.levelTwoId = this.baselLevel.levelTwo
                  item.children.forEach((child) => {
                  if(this.levelTwoId === child.id){
                    this.levelTwoLabel = child.label
                    this.findItemById();
                    this.levelThreeList.forEach((item) => {
                      if(this.levelTwoId != null){
                        this.levelThreeId = this.baselLevel.levelThree
                          if(this.levelThreeId == item.id){
                            this.levelThreeLabel = item.label
                          }
                      }
                    })
                  }
                })
                }
              }
          })
        }
      },
      editlevelThree(){
        this.levelThreeLabel = null;
        this.levelThreeId = null;
        this.localBaselLevel.levelThree = this.levelThreeId;
        this.$emit('basel-level-change', this.localBaselLevel);
      },
      editlevelTwo(){
        this.levelTwoLabel = null;
        this.levelTwoId = null;
        this.localBaselLevel.levelTwo = this.levelTwoId;
        this.$emit('basel-level-change', this.localBaselLevel);
        this.editlevelThree();
      },
      editlevelOne(){
        this.levelOneLabel = null;
        this.levelOneId = null;
        this.localBaselLevel.levelOne = this.levelOneId;
        this.$emit('basel-level-change', this.localBaselLevel);
        this.editlevelTwo();
      },
      selectlevelOne(event){
        this.levelOneLabel = event.target.textContent;
        this.basel.forEach((item) => {
          if(this.levelOneLabel === item.label){
            this.levelOneId = item.id
          }
        })
        this.localBaselLevel.levelOne = this.levelOneId;
        this.$emit('basel-level-change', this.localBaselLevel);
      },
      selectlevelTwo(event, parent){
        this.levelTwoLabel = event.target.textContent;
        parent.children.forEach((child) => {
          if(this.levelTwoLabel === child.label){
            this.levelTwoId = child.id
          }
        })
        this.localBaselLevel.levelTwo = this.levelTwoId;
        this.$emit('basel-level-change', this.localBaselLevel);
        this.findItemById();
      },
      selectlevelThree(event, thirdId){
        this.levelThreeLabel = event.target.textContent;
        this.levelThreeId = thirdId
        this.localBaselLevel.levelThree = this.levelThreeId
        this.$emit('basel-level-change', this.localBaselLevel);
      },
      findItemById(){
        const levelOne = this.basel.find(item => item.id === this.levelOneId);
        const levelTwo = levelOne.children.find(item => item.id === this.levelTwoId);
        this.levelThreeList = levelTwo.children;
      }
    },
  };
  </script>
  <style>
  .listOption{
    display: flex;
    justify-content: start;
    padding: 20px 32px 20px 32px;
    border-width: 0px 0px 1px 0px;
    border-color: var(--color_gray_xlight);
    background-color: transparent;
    text-justify: initial;
    cursor: pointer;
  }
  
  .levelOneSelected{
    display: flex;
    justify-content: start;
    padding: 20px 32px 20px 32px;
    border-width: 0px;
    background-color: var(--color_brand_medium);
    color: white;
    font-weight: 600;
    gap: 24px;
  }
  
  .editButton{
    display: flex;
    justify-content: center;
    padding: 4px 8px;
    border-width: 0px;
    border-radius: 2px;
    background-color: white;
    color: var(--color_brand_medium);
    gap: 8px;
    font-weight: 700;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .editButton:hover{
    background-color: var(--color_brand_xlight);
    color: var(--color_brand_xdark)
  }
  
  .levelTwoSelected{
    display: flex;
    justify-content: start;
    padding: 20px 32px 20px 64px;
    border-width: 0px;
    background-color: var(--color_brand_light);
    color: var(--color_brand_xdark);
    font-weight: 700;
    gap: 24px;
  }

  .levelThreeSelected{
    display: flex;
    justify-content: start;
    padding: 20px 32px 20px 96px;
    border-width: 0px;
    background-color: var(--color_brand_xlight);
    color: var(--color_brand_xdark);
    font-weight: 700;
    gap: 24px;
  }
  
  </style>
  