<template>
  <SpeedDial v-if="bdpoIsFilled == true
    " style="right: 20px; bottom: 20px; position: fixed" :model="dialItems" :radius="120" type="quarter-circle"
    direction="up-left" :tooltipOptions="{ position: 'top' }">
    <template #item="{ item }">
      <div v-tooltip.top="{ value: item.label }">
        <Button :icon="item.icon" @click="item.command" class="p-button p-component p-button-icon-only p-button-rounded"
          :disabled="item.disabled" />
      </div>
    </template>
  </SpeedDial>
  <div>
    <Toast />

    <div style="
      display: flex;
      align-items: start;
      gap: 32px;
      width: 100%;
      padding-top: 48px;
    ">
      <div style="width: 100%">
        <div style="display: flex; align-items: start; gap: 4px">
          <div class="h4" style="margin-bottom: 4px">{{ this.$langGetValue("bdpoEventType") }}</div>
          <i style="color: var(--color_feedback_negative); cursor: help" title="Campo Obrigatório">*</i>
        </div>
        <div style="margin-bottom: 16px">
          <span class="body3" style="color: var(--color_gray_medium)">{{ this.$langGetValue("bdpoEventTypeDescription")
          }}</span>
        </div>
        <Dropdown :class="{ 'invalid-dropdown': this.selectedEvent.length === 0 && buttonClicked }"
          v-if="this.bdpoIsFilled == false" v-model="this.selectedEvent" :options="this.eventOptions" optionLabel="evento"
          :placeholder="this.bdpoEventTypePlaceholder" style="width: 100%" />
        <Chip v-if="selectedEvent != null && this.bdpoIsFilled == true" :label="selectedEvent.evento"
          class="secondaryChip" />
      </div>
      <div v-if="this.bdpoIsFilled == false" style="width: 100%">
        <div class="h4" style="margin-bottom: 4px">{{ this.$langGetValue("bdpoRootLoss") }}</div>
        <div style="margin-bottom: 16px">
          <span class="body3" style="color: var(--color_gray_medium)">{{ this.$langGetValue("bdpoRootDescription")
          }}</span>
        </div>
        <InputNumber v-model="rootID" inputId="rootID" style="width: 100%" :useGrouping="false" />
      </div>
    </div>

    <div v-if="rootID != null && this.bdpoIsFilled == true" style="
      display: flex;
      align-items: start;
      gap: 32px;
      width: 100%;
      padding-top: 48px;
    ">
      <div style="width: 100%">
        <div style="display: flex; align-items: start; gap: 4px">
          <div class="h4" style="margin-bottom: 4px">{{ this.$langGetValue("bdpoRootIncident") }}</div>
        </div>
        <div style="margin-bottom: 16px">
          <span class="body3" style="color: var(--color_gray_medium)">{{ this.$langGetValue("bdpoRootIncidentNumber")
          }}</span>
        </div>
        <div @click="loadIncidents" class="temporaryChip" style="
          cursor: pointer;
          background-color: var(--color_gray_xlight);
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 8px 16px;
        ">
          <Skeleton v-tooltip.top="Critico" shape="circle" size="1rem" :style="{
            'background-color': 'var(--color_incident_critical)',
          }">
          </Skeleton>
          <div style="
            padding-left: 8px;
            display: flex;
            font-weight: 700;
            line-height: 0px;
          ">
            {{ `INC-${this.rootID}` }}
          </div>
          <i class="pi pi-arrow-up-right" style="padding-left: 8px; color: var(--color_gray_medium)"></i>
        </div>
      </div>
      <div style="width: 100%">
        <div class="h4" style="margin-bottom: 4px">{{ this.$langGetValue("bdpoRootIncidentRegistration") }}</div>
        <div style="margin-bottom: 16px">
          <span class="body3" style="color: var(--color_gray_medium)">{{
            this.$langGetValue("bdpoRootIncidentRegistrationDescription") }}</span>
        </div>
        <span class="body3" style="font-weight: 700">10/10/2010</span>
      </div>
    </div>

    <div style="
      display: flex;
      align-items: start;
      gap: 32px;
      width: 100%;
      padding-top: 48px;
    ">
      <div style="width: 100%">
        <div style="display: flex; align-items: start; gap: 4px">
          <div class="h4" style="margin-bottom: 4px">{{ this.$langGetValue("bdpoAccounted") }}</div>
          <i style="color: var(--color_feedback_negative); cursor: help" title="Campo Obrigatório">*</i>
        </div>
        <div style="margin-bottom: 16px">
          <span class="body3" style="color: var(--color_gray_medium)">{{ this.$langGetValue("bdpoAccountedDescription")
          }}</span>
        </div>
        <Dropdown :class="{ 'invalid-dropdown': this.selectedPSL.length === 0 && buttonClicked }"
          v-if="this.bdpoIsFilled == false" v-model="this.selectedPSL" :options="this.PSLOptions" optionLabel="psl"
          :placeholder="this.bdpoAccountedPlaceholder" style="width: 100%" />
        <Chip v-if="selectedPSL != null && this.bdpoIsFilled == true" :label="selectedPSL.psl" class="secondaryChip" />
      </div>
      <div style="width: 100%">
        <div style="display: flex; align-items: start; gap: 4px">
          <div class="h4" style="margin-bottom: 4px">{{ this.$langGetValue("bdpoCourtOrder") }}</div>
          <i style="color: var(--color_feedback_negative); cursor: help" title="Campo Obrigatório">*</i>
        </div>
        <div style="margin-bottom: 16px">
          <span class="body3" style="color: var(--color_gray_medium)">{{ this.$langGetValue("bdpoCourtOrderDescription")
          }}</span>
        </div>
        <Dropdown :class="{ 'invalid-dropdown': this.selectedJudicialOrigin.length === 0 && buttonClicked }"
          v-if="this.bdpoIsFilled == false" v-model="this.selectedJudicialOrigin" :options="this.judicialOriginOptions"
          optionLabel="ordem" :placeholder="this.bdpoCourtOrderPlaceholder" style="width: 100%" />
        <Chip v-if="selectedJudicialOrigin != null && this.bdpoIsFilled == true" :label="selectedJudicialOrigin.ordem"
          class="secondaryChip" />
      </div>
    </div>

    <div style="
      display: flex;
      align-items: start;
      gap: 32px;
      width: 100%;
      padding-top: 48px;
    ">
      <div style="width: 100%">
        <div style="display: flex; align-items: start; gap: 4px">
          <div class="h4" style="margin-bottom: 4px">{{ this.$langGetValue("bdpoBusinessFunction") }}</div>
          <i style="color: var(--color_feedback_negative); cursor: help" title="Campo Obrigatório">*</i>
        </div>
        <div style="margin-bottom: 16px">
          <span class="body3" style="color: var(--color_gray_medium)">{{
            this.$langGetValue("bdpoBusinessFunctionDescription") }}</span>
        </div>
        <Dropdown :class="{ 'invalid-dropdown': this.selectedBusinessFunction.length === 0 && buttonClicked }"
          v-if="this.bdpoIsFilled == false" v-model="this.selectedBusinessFunction"
          :options="this.businessFunctionOptions" optionLabel="funcao" :placeholder="this.bdpoBusinessFunctionPlaceholder"
          style="width: 100%" />
        <Chip v-if="selectedBusinessFunction != null && this.bdpoIsFilled == true"
          :label="selectedBusinessFunction.funcao" class="secondaryChip" />
      </div>
      <div style="width: 100%">
        <div style="display: flex; align-items: start; gap: 4px">
          <div class="h4" style="margin-bottom: 4px">{{ this.$langGetValue("bdpoLossStatus") }}</div>
          <i style="color: var(--color_feedback_negative); cursor: help" title="Campo Obrigatório">*</i>
        </div>
        <div style="margin-bottom: 16px">
          <span class="body3" style="color: var(--color_gray_medium)">{{ this.$langGetValue("bdpoLossStatusDescription")
          }}</span>
        </div>
        <Dropdown :class="{ 'invalid-dropdown': this.selectedStatus.length === 0 && buttonClicked }"
          v-if="this.bdpoIsFilled == false" v-model="this.selectedStatus" :options="this.statusOptions"
          optionLabel="status" :placeholder="this.bdpoLossStatusPlaceholder" style="width: 100%" />
        <Chip v-if="selectedStatus != null && this.bdpoIsFilled == true" :label="selectedStatus.status"
          class="secondaryChip" />
      </div>
    </div>

    <div style=" display: flex; align-items: start; flex-direction: column; width: 100%; padding-top: 48px;">
      <div style="display: flex; align-items: start; gap: 4px">
        <div class="h4" style="margin-bottom: 4px">{{ this.$langGetValue("bdpoBaselLevel") }}</div>
        <i style="color: var(--color_feedback_negative); cursor: help" title="Campo Obrigatório">*</i>
      </div>
      <div style="margin-bottom: 16px">
        <span class="body3" style="color: var(--color_gray_medium)">{{ this.$langGetValue("bdpoBaselLevelDescription")
        }}</span>
      </div>
      <BaselLevel :editMode="!bdpoIsFilled" :baselLevel="baselLevel" @basel-level-change="baselLevelComplete" />
    </div>

    <div v-if="this.bdpoIsFilled == false" style="
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 16px;
      width: 100%;
      padding-top: 64px;
    ">
      <button @click="setButtonState" class="primaryButton">
        {{ this.$langGetValue("bdpoConfirmButton") }}
      </button>
      <button @click="cancelFilling" class="negativeButton">{{ this.$langGetValue("bdpoCancelButton") }}</button>
    </div>
  </div>
</template>

<script>

import BaselLevel from './BaselLevel.vue';
import IncidentService from "../service/IncidentService";

export default {
  components: {
    BaselLevel,
  },
  data() {
    return {
      baselLevel: { levelOne: this.query.baselLevel.levelOne, levelTwo: this.query.baselLevel.levelTwo, levelThree: this.query.baselLevel.levelThree },
      bdpoIsFilled: false,
      selectedPSL: { code: -1 },
      PSLOptions: [
        { psl: "0 Não Aplicável", code: 0 },
        { psl: "1 A perda está contabilizada na PSL", code: 1 },
        { psl: "2 A perda não está contabilizada na PSL", code: 2 },
      ],
      selectedStatus: { code: -1 },
      statusOptions: [
        { status: "0 Não Aplicável", code: 0 },
        { status: "1 Perda ainda não encerrada", code: 1 },
        { status: "2 Perda encerrada", code: 2 },
      ],
      selectedBusinessFunction: { code: -1 },
      businessFunctionOptions: [
        { funcao: "0 Não Aplicável", code: 0 },
        { funcao: "1 Administração", code: 1 },
        { funcao: "2 Finanças Corporativas", code: 2 },
        { funcao: "3 Negociação e Vendas", code: 3 },
        { funcao: "4 Pagamentos e Liquidações", code: 4 },
        { funcao: "5 Sistemas", code: 5 },
        { funcao: "6 Subscrição", code: 6 },
        { funcao: "7 Terceirização", code: 7 },
      ],
      selectedJudicialOrigin: { code: -1 },
      judicialOriginOptions: [
        { ordem: "0 Não Aplicável", code: 0 },
        { ordem: "1 A perda está relacionada a uma ação judicial", code: 1 },
        {
          ordem: "2 A perda não está relacionada a uma ação judicial",
          code: 2,
        },
      ],
      rootID: null,
      selectedEvent: { code: -1 },
      eventOptions: [
        { evento: "1 Perda Raiz", code: 1 },
        { evento: "2 Quase Perda Raiz", code: 2 },
        { evento: "3 Perda Descendente", code: 3 },
        { evento: "4 Quase Perda Descendente", code: 4 },
        { evento: "5 Recuperação", code: 5 },
        { evento: "6 Atualização", code: 6 },
      ],
      buttonClicked: false,
      queryLocal: this.query,
      isThirdLevelBaselComplete: false,
      dialItems: [
        {
          label: this.$langGetValue("buttonEdit"),
          icon: 'pi pi-pencil',
          disabled: false,
          command: () => {
            this.bdpoIsFilled = false
          }
        },
        {
          label: this.$langGetValue("fowardButton"),
          icon: 'pi pi-envelope',
          disabled: false,
          command: () => {
          }
        }
      ],
      bdpoEventTypePlaceholder: null,
      bdpoCourtOrderPlaceholder: null,
      bdpoBusinessFunctionPlaceholder: null,
      bdpoLossStatusPlaceholder: null,
      bdpoAccountedPlaceholder: null,
    };
  },
  props: {
    query: Object,
  },
  mounted() {
    this.fillValues();
    this.settingPlaceholderStrings();
  },
  watch: {
    // selectedEvent(newValue){
    //   this.queryLocal.bdpo.eventType = newValue.code
    // },
    // selectedJudicialOrigin(newValue){
    //   this.queryLocal.bdpo.judicialOrigin = newValue.code
    // },
    // selectedBusinessFunction(newValue){
    //   this.queryLocal.bdpo.businessFunction = newValue.code
    // },
    // selectedPSL(newValue){
    //   this.queryLocal.bdpo.accountedPsl = newValue.code
    // },
    // selectedStatus(newValue){
    //   this.queryLocal.bdpo.lossStatus = newValue.code
    // },
    // rootID(newValue){
    //   this.queryLocal.bdpo.rootLoss = newValue
    // },
  },
  methods: {
    cancelFilling() {
      const fullURL = window.location.href
      const toBeRemoved = "&bdpo=true"
      window.location.href = fullURL.substring(toBeRemoved.length)
      location.reload();
    },

    settingPlaceholderStrings() {
      this.bdpoAccountedPlaceholder = this.$langGetValue("bdpoAccountedPlaceholder")
      this.bdpoLossStatusPlaceholder = this.$langGetValue("bdpoLossStatusPlaceholder")
      this.bdpoBusinessFunctionPlaceholder = this.$langGetValue("bdpoBusinessFunctionPlaceholder")
      this.bdpoCourtOrderPlaceholder = this.$langGetValue("bdpoCourtOrderPlaceholder")
      this.bdpoEventTypePlaceholder = this.$langGetValue("bdpoEventTypePlaceholder")
    },

    loadIncidents() {
      var newWindow = window.open(
        window.location.origin + "/#/incident/search?id=" + this.rootID
      );

      if (newWindow) {
        newWindow.onload = function () {
          newWindow.location.reload();
        };
      }
    },

    fillValues() {

      var checkList = 0;

      if (this.queryLocal.bdpo == null) {
        return
      }

      if (this.queryLocal.bdpo.eventType != null) {
        this.eventOptions.forEach((option) => {
          if (option.code === this.queryLocal.bdpo.eventType) {
            this.selectedEvent = option;
            checkList++
          }
        });
      }

      if (this.queryLocal.bdpo.lossStatus != null) {
        this.statusOptions.forEach((option) => {
          if (option.code === this.queryLocal.bdpo.lossStatus) {
            this.selectedStatus = option;
            checkList++
          }
        });
      }

      if (this.queryLocal.bdpo.businessFunction != null) {
        this.businessFunctionOptions.forEach((option) => {
          if (option.code === this.queryLocal.bdpo.businessFunction) {
            this.selectedBusinessFunction = option;
            checkList++
          }
        });
      }

      if (this.queryLocal.bdpo.judicialOrigin != null) {
        this.judicialOriginOptions.forEach((option) => {
          if (option.code === this.queryLocal.bdpo.judicialOrigin) {
            this.selectedJudicialOrigin = option;
            checkList++
          }
        });
      }

      if (this.queryLocal.bdpo.accountedPsl != null) {
        this.PSLOptions.forEach((option) => {
          if (option.code === this.queryLocal.bdpo.accountedPsl) {
            this.selectedPSL = option;
            checkList++
          }
        });
      } else {
        this.selectedPSL = this.PSLOptions[0];
        this.queryLocal.bdpo.accountedPsl = this.selectedPSL
        checkList++
      }

      if (this.queryLocal.bdpo.rootLoss != null) {
        this.rootID = this.queryLocal.bdpo.rootLoss;
        checkList++
      }

      if (this.queryLocal.baselLevel.levelThree != null) {
        this.isThirdLevelBaselComplete = true
      }

      if (checkList >= 5) {
        this.bdpoIsFilled = true;
      }

    },

    // navigateToRoot() {
    //   console.log("Indo para a perda raiz");
    // },

    setButtonState() {
      this.buttonClicked = true;
      this.setBdpoFilledState();
    },
    baselLevelComplete(baselLevel) {

      this.queryLocal.baselLevel = baselLevel

      if (this.queryLocal.baselLevel.levelThree != null) {
        this.isThirdLevelBaselComplete = true
      } else if (this.queryLocal.baselLevel.levelThree == null) {
        this.isThirdLevelBaselComplete = false
      }

    },
    setBdpoFilledState() {

      if (
        this.selectedEvent.code != -1 &&
        this.isThirdLevelBaselComplete === true &&
        this.selectedJudicialOrigin.code != -1 &&
        this.selectedBusinessFunction.code != -1 &&
        this.selectedStatus.code != -1
      ) {

        var payload = {
          baselLevel: this.queryLocal.baselLevel,
          bdpo: {
            eventType: this.selectedEvent.code,
            judicialOrigin: this.selectedJudicialOrigin.code,
            businessFunction: this.selectedBusinessFunction.code,
            accountedPsl: this.selectedPSL.code,
            lossStatus: this.selectedStatus.code,
            rootLoss: this.rootID
          }
        }

        IncidentService.patchIncident(payload, this.queryLocal.id).then((response) => {
          if (response.success != false) {
            this.bdpoIsFilled = true;
            this.$toast.add({
              severity: "success",
              summary: this.$langGetValue("toast16"),
              life: 3000,
            });
          }
        })
      } else {
        this.$toast.add({
          severity: "error",
          summary: this.$langGetValue("toast15"),
          life: 3000,
        });
      }
    },
  },
};
</script>

<style>
.invalid-dropdown {
  border-width: 1px;
  border-color: var(--color_feedback_negative);
}
</style>
