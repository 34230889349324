import BackendService from './BackendService';

export default class LlmService {

    // static summaryIncidents(list) {

    //     let payload = {
    //         "engine": "gpt-4-o-storm",
    //         "ids": list
    //     }

    //     return BackendService.post("/llm/incident/incident-summary", payload).then(resp => {
    //         return resp
    //     })
    // }

    static summaryIncidents(data){
 
        let payload = {
            "engine": "gpt-4-o-storm",
            ids: data
        }
 
        return BackendService.post("/llm/incident/incident-summary", payload).then(resp => {
            return resp
        })
    }

}